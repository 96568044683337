import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import NotFound from "./containers/NotFound";
import Home from "./components/HomeFiles/Home";
import AgentInfo from "./components/agent/agentInfo/AgentInfo";
import { AuthenticatedRoute } from "./components/common/AuthenticatedRoute";
import ProductionStatisticsinfo from "./components/ProductionStatisticsFiles/ProductionStatistics/ProductionStatisticsinfo";
import ProductionStatisticsSummaryInfo from "./components/ProductionStatisticsFiles/ProductionStatisticsSummary/ProductionStatisticsSummaryInfo";
import history from "./utils/history";
import LandingPage from "./components/LandingPage";
import LoginWithLanding from "./containers/LoginWithLanding";
import CommissionLossReport from "./components/ClrFiles/CommissionLossReportInfo";
import NewFeatures from "./components/NewFeatures";
import DownLineCommunicationsInfo from "./components/DownLineCommunications/DownLineCommunicationsInfo";
import ContractRequestsInfo from "./components/contractRequests/ContractRequestsInfo";
import Leads from "./components/leads/Leads";
import PasswordReset from "./components/common/PasswordReset";
import Register from "./components/common/Register/Register";

const Routes = ({ agencyLoaded, ...props }) => (
  <Router history={history}>
    <Switch>
      <Route path="/login" component={LoginWithLanding} exact />
      <AuthenticatedRoute path="/" exact component={Home} />
      <AuthenticatedRoute path="/main" exact component={LandingPage} />
      <AuthenticatedRoute path="/AgentList" exact component={AgentInfo} />
      <Route path="/register" exact component={Register} />
      <AuthenticatedRoute
        path="/Production"
        exact
        component={ProductionStatisticsinfo}
      />
      <AuthenticatedRoute
        path = "/ProductionStatisticsSummary"
        exact
        component = {ProductionStatisticsSummaryInfo}
      />
      <AuthenticatedRoute
        path="/CommissionLossReport"
        exact
        component={CommissionLossReport}
      />
      <AuthenticatedRoute path="/NewFeatures" exact component={NewFeatures} />
      <AuthenticatedRoute
        path="/DownlineCommunications"
        exact
        component={DownLineCommunicationsInfo}
      />
      <AuthenticatedRoute
        path="/PendingRequests"
        exact
        component={ContractRequestsInfo}
      />
      <AuthenticatedRoute path="/Leads" exact component={Leads} />
      <Route path="/resetpassword" exact component={PasswordReset } />
      {/* <Route path="/linkExpired" exact component={LinkExpired} /> */}
      {/* Finally, catch all unmatched routes */}
      <Route component={NotFound} />
    </Switch>
  </Router>
);

export default Routes;
