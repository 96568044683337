import { Typography, Paper } from "@mui/material";
import { useSelector} from "react-redux";
import ReadyToSellByCarrierDataGrid from "./ReadyToSellByCarrierDataGrid.jsx";
import classes from "./ReadyToSellByCarrier.module.css";

const ReadyToSellByCarrier = () => {  
  const {
    agencyReadyToSellByCarrier,
  } = useSelector((state) => state.readyToSellByCarrier);
  return (
    <>
      <Typography
        className={classes.typography}
        gutterBottom
        variant="h5"
        component="h3"
      >
        {`Ready to Sell (RTS) Agents by Carrier ${
          agencyReadyToSellByCarrier && agencyReadyToSellByCarrier.length > 0
            ? ` (${agencyReadyToSellByCarrier[0].planYear})`
            : ""
        }`}
      </Typography>
      <Paper className={classes.paper}>
        <ReadyToSellByCarrierDataGrid
          tableData={agencyReadyToSellByCarrier}
          isLoading={false}
        />
      </Paper>
    </>
  );
};

export default ReadyToSellByCarrier;
