import {
    SET_PRODUCTION_STATISTICS_FOR_AGENCY,
    SET_PRODUCTION_STATISTICS_FOR_AGENCY_ERROR,
    CLEAR_PRODUCITON_STATISTICS_FOR_AGENCY,
    PRODUCTION_STATISTICS_FOR_AGENCY_LOADING,
    SET_PRODUCTION_STATISTICS_FOR_AGENCY_FILTER_MODEL,
    CLEAR_PRODUCTION_STATISTICS_FOR_AGENCY_FILTER_MODEL,
}from "../../constants/action-types";

import { getProductionStatisticsForAgency } from "../../../services/GraphQl/BDSWebService";

export const setProductionStatisticsForAgency = (data) => {
  return {
    type: SET_PRODUCTION_STATISTICS_FOR_AGENCY,
    payload: data,
  };
};

export const setProductionStatisticsForAgencyError = (error) => {
  return {
    type: SET_PRODUCTION_STATISTICS_FOR_AGENCY_ERROR,
    payload: error,
  };
};

export const clearProductionStatisticsForAgency = () => {
  return {
    type: CLEAR_PRODUCITON_STATISTICS_FOR_AGENCY,
  };
};

export const setProductionStatisticsForAgencyLoading = (data) => {
  return {
    type: PRODUCTION_STATISTICS_FOR_AGENCY_LOADING,
    payload: data,
  };
};

export const setProductionStatisticsForAgencyFilterModel = (data) => {
  return {
    type: SET_PRODUCTION_STATISTICS_FOR_AGENCY_FILTER_MODEL,
    payload: data,
  };
};

export const clearProductionStatisticsForAgencyFilterModel = () => {
  return {
    type: CLEAR_PRODUCTION_STATISTICS_FOR_AGENCY_FILTER_MODEL,
  };
};

export const getProductionStatisticsForAgencyAction = (agencyId) => {
  return async (dispatch) => {
    dispatch(setProductionStatisticsForAgencyLoading(true));
    try {
      let ProResultData = await getProductionStatisticsForAgency(agencyId);
      dispatch(setProductionStatisticsForAgency(ProResultData));
    } catch (error) {
      dispatch(setProductionStatisticsForAgencyError(error));
    } finally {
      dispatch(setProductionStatisticsForAgencyLoading(false));
    }
  };
};
