import { useState, useCallback, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import locale from "date-fns/locale/en-US";
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";

import AgentHierarchyModal from "../../agentTileFiles/AgentHierarchyModal";
import AgentTileInfo from "../agentTile/AgentTileInfo";
import AgentsColumns from "./AgentsColumns";
import classes from "../../../components/DataGridTemplate/DataGridTemplate.module.css";
import StripedDataGridTemplate from "../../../components/DataGridTemplate/StripedDataGridTemplate";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import AgentsData from "./AgentsData";
import DataGridNoResults from "../../DataGridTemplate/DataGridNoResults";

const Agents = (props) => {
  const filteredData = props.data;
  const isLoading = props.isAgentDataLoading;
  const [openAH, setOpenAH] = useState(false);
  const [agentName, setAgentName] = useState("");
  const [currentAgentId, setCurrentAgentId] = useState("");
  const [uplineArr, setUplineArr] = useState([]);
  const { agentsContractInfoData } = props;
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    agentId: false,
  });
  const filterModel = useSelector(
    (state) => state.agentsContractInfo.filterModel
  );

  const handleUplineClick = useCallback((event, cellValues) => {
    if (cellValues === undefined) {
      setAgentName("");
      setCurrentAgentId("");
    } else {
      setAgentName(cellValues.row.agentName);
      setCurrentAgentId(cellValues.row.agentId);
    }
    setOpenAH((prevOpenAH) => !prevOpenAH);
  }, []);

  const columns = AgentsColumns({ handleUplineClick });
  const uniqueRows = AgentsData({
    data: filteredData,
  });

  const StyledGridOverlay = styled("div")(({ theme }) => {
    const isLightMode = theme.palette.mode === "light";
    return {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      backgroundColor: isLightMode
        ? "rgba(255, 255, 255, 0.9)"
        : "rgba(18, 18, 18, 0.9)",
    };
  });

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            position: "absolute",
            inset: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.primary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }

  function CustomLoadingOverlay() {
    const [progress, setProgress] = useState(10);

    useEffect(() => {
      const timer = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 0 : prevProgress + 10
        );
      }, 800);
      return () => {
        clearInterval(timer);
      };
    }, []);

    return (
      <StyledGridOverlay>
        <CircularProgressWithLabel value={progress} />
        <Box sx={{ mt: 2 }}>Loading rows…</Box>
      </StyledGridOverlay>
    );
  }

  const CustomToolbar = useMemo(
    () => () => {
      const sxToolBarContainer = { fontSize: 12, paddingBottom: 1 };
      const sxToolBarColumnsButton = {
        color: "black",
        backgroundColor: "whitesmoke",
      };

      return (
        <GridToolbarContainer sx={sxToolBarContainer}>
          <GridToolbarFilterButton sx={sxToolBarColumnsButton} />
          <GridToolbarExport sx={sxToolBarColumnsButton} />
        </GridToolbarContainer>
      );
    },
    []
  );
  const StripedDataGrid = StripedDataGridTemplate();

  const StripedDataGridNoResults = () => {
    return <DataGridNoResults columns={columns} uniqueRows={uniqueRows} />;
  };

  const sxBox = {
    height: 640,
    width: "100%",
    "& .super-app-theme--header": {
      backgroundColor: "rgba(27, 133, 243, 0.8)",
    },
  };

  const paginationProps = {
    pagination: true,
    pageSize: 25,
    rowsPerPageOptions: [25, 50, 100],
  };

  const getDetailPanelHeight = useCallback(() => 400, []);
  const getContent = useCallback(
    (row) => {
      return (
        <AgentTileInfo
          agentId={row.agentId}
          agentsContractInfoData={agentsContractInfoData}
        />
      );
    },
    [agentsContractInfoData]
  );

  if (uniqueRows.length === 0 && isLoading === false) {
    return (
      <div className={classes.myClass}>
        <Box sx={sxBox}>
          <StripedDataGridNoResults />
        </Box>
      </div>
    );
  } else {
    /// Return the DataGrid
    return (
      <div className={classes.myClass}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={locale}
        >
          <Box sx={sxBox}>
            <StripedDataGrid
              slots={{
                loadingOverlay: CustomLoadingOverlay,
                toolbar: CustomToolbar,
              }}
              loading={isLoading}
              rows={uniqueRows}
              columns={columns}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setColumnVisibilityModel(newModel)
              }
              pagination={paginationProps}
              disableRowSelectionOnClick
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
              sx={{ flex: 1 }}
              rowThreshold={0}
              getDetailPanelContent={({ row }) => getContent(row)}
              getDetailPanelHeight={getDetailPanelHeight}
              density="compact"
              initialState={{
                filter: {
                  filterModel: filterModel,
                },
              }}
            />
          </Box>
        </LocalizationProvider>
        {openAH === true && (
          <AgentHierarchyModal
            currentAgentId={currentAgentId}
            openAH={openAH}
            toggleAH={handleUplineClick}
            agentName={agentName}
            uplineArr={uplineArr}
            setUplineArr={setUplineArr}
          />
        )}
      </div>
    );
  }
};

export default Agents;
