import { useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { alpha, styled } from "@mui/material/styles";
import classes from "../../DataGridTemplate/DataGridTemplate.module.css";
import { setFilteredTotals } from "../../../store/actions/production/filteredProductionTotals";
import ProductionStatisticsSummaryColumns from "./ProductionStatisticsSummaryColumns";
import ProductionStatisticsSummaryData from "./ProductionStatisticsSummaryData";
import _ from "lodash";

import {
  DataGridPro,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  gridFilteredSortedRowIdsSelector,
  useGridApiRef,
} from "@mui/x-data-grid-pro";

const ProductionStatisticsSummaryMUI = () => {
  const data =  useSelector(
    (state) => state.productionStatisticsForAgency.dataSummary
  );
  const getMaxYear = () => {
    if (data.length > 0) {
      if (data.length > 0) {
        return Math.max(...data.map((row) => row.salesYear));
      }
    }
    return 0;
  };
  const maxYear = getMaxYear();

  const getFilterModel = () => {
    return {
      items: [
        {
          id: 0,
          field: "salesYear",
          operator: "equals",
          value: maxYear.toString(),
        },
      ],
    };
  };
  const [filterModel, setFilterModel] = useState(getFilterModel());
  const isLoading = false;
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();

  const columns = ProductionStatisticsSummaryColumns();

  const uniqueRows = data
    ? ProductionStatisticsSummaryData({
        data: data,
      }).sort((a, b) => a.agentName.localeCompare(b.agentName))
    : [];

  const debouncedSetFilterModel = _.debounce((newFilterModel) => {
    setFilterModel(newFilterModel);
  }, 1000);

  const sxToolBarContainer = { fontSize: 12, paddingBottom: 1 };
  const sxToolBarColumnsButton = {
    color: "black",
    backgroundColor: "whitesmoke",
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={sxToolBarContainer}>
        <GridToolbarColumnsButton sx={sxToolBarColumnsButton} />
        <GridToolbarFilterButton sx={sxToolBarColumnsButton} />
        <GridToolbarDensitySelector sx={sxToolBarColumnsButton} />
        <GridToolbarExport sx={sxToolBarColumnsButton} />
      </GridToolbarContainer>
    );
  };

  const ODD_OPACITY = 0.2;
  const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        "@media (hover: none)": {
          backgroundColor: "lightgrey",
        },
      },
      "&.Mui-selected": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity
        ),
        "&:hover, &.Mui-hovered": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity
          ),
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity
            ),
          },
        },
      },
    },
  }));

  const sxBox = {
    height: 640,
    width: "auto",
    "& .super-app-theme--header": {
      backgroundColor: "rgba(27, 133, 243, 0.8)",
    },
  };

  const MemoizedDataGrid = memo(StripedDataGrid);

  return (
    <div className={classes.myClass}>
      <Box sx={sxBox}>
        <MemoizedDataGrid
          apiRef={apiRef}
          slots={{ toolbar: CustomToolbar }}
          loading={isLoading}
          rows={uniqueRows.sort((a, b) =>
            (a.agentname || "").localeCompare(b.agentname || "")
          )}
          columns={columns.map((column) => ({ ...column, flex: 1 }))}
          filterModel={filterModel}
          onFilterModelChange={(newFilterModel) => {
            debouncedSetFilterModel(newFilterModel);
          }}
          pagination={{ rowsPerPageOptions: [25, 50, 100], pageSize: 25 }}
          disableRowSelectionOnClick
          {...(maxYear && {
            initialState: {
              filter: {
                filterModel: filterModel,
              },
            },
          })}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }
          onStateChange={() => {
            const filteredSortedRowIds =
              gridFilteredSortedRowIdsSelector(apiRef);
            const filteredSortedRows = filteredSortedRowIds.map((id) =>
              apiRef.current.getRow(id)
            );
            const maTotal = filteredSortedRows.reduce(
              (sum, row) => sum + parseInt(row.maTotal),
              0
            );
            const msTotal = filteredSortedRows.reduce(
              (sum, row) => sum + parseInt(row.medsuppTotal),
              0
            );
            const pdpTotal = filteredSortedRows.reduce(
              (sum, row) => sum + parseInt(row.pdpTotal),
              0
            );

            dispatch(
              setFilteredTotals({
                maTotal: maTotal,
                msTotal: msTotal,
                pdpTotal: pdpTotal,
              })
            );
          }}
        />
      </Box>
    </div>
  );
};

export default ProductionStatisticsSummaryMUI;
