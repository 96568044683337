import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import DashboardTitle from "../DashboardTitle";
import {
  AppBar,
  Toolbar,
  ButtonGroup,
  IconButton,
  Grid,
  Typography,
} from "@mui/material";
import { clearStore as clearStoreAction } from "../../store/actions/index";
import { setCriteria } from "../../store/actions/production/productionActions";
import { clearAgentListFilterModel } from "../../store/actions/agents/agentsContractInfo";
import LinkButtonWithTooltip from "./LinkButtonWithTooltip";
import history from "../../utils/history";
import { persistor } from "../../store";
import {
  Group,
  Business,
  Dashboard,
  HowToVoteOutlined,
  DomainAdd,
  Share,
  GroupAdd,
} from "@mui/icons-material";
import ContractRequestModal from "../common/ContractRequestModal";
import client from "../../services/GraphQl/client";
import HtmlToolTip from "./HtmlTooltip";
import { setContractRequests } from "../../store/actions/contract-requests/contractRequests";
import {
  crSubscribe,
  crUnsubscribe,
  ocrSubscribe,
  ocrUnsubscribe,
} from "../../services/GraphQl/BDSWebService";
import MobileMenu from "./MobileMenu";
import ShareAway from "./ShareAway";

const theme = createTheme({
  palette: {
    primary: { main: "#444242" },
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      "HelveticaNeueLTStd",
      "Helvetica Neue LT Std",
      "HelveticaNeue-Light",
      "Helvetica Neue Light",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      "Lucida Grande",
      "sans-serif",
    ].join(","),
  },
});

const useStyles = makeStyles()((theme) => {
  return {
    home: {
      color: "#FFFFFF",
    },
    content: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "block",
      },
    },
    shareButton: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "inline-flex",
      },
    },
    mobileContent: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
    exitButton: {
      textAlign: "end",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
  };
});

const NavBar = (props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [contractModalOpen, setContractModalOpen] = useState(false);
  const [openShareAway, setOpenShareAway] = useState(false);
  const [newOcrCount, setNewOcrCount] = useState(0);
  const recruiterCode = useSelector(
    (recruiterStore) =>
      recruiterStore.recruiterStore.recruiterInfo?.recruiterCode
  );

  useEffect(() => {
    dispatch(setContractRequests());
    const agencyId = sessionStorage.getItem("agencyId");
    crSubscribe(agencyId, (callback) => {
      if (callback) {
        dispatch(setContractRequests());
      }
    });
    ocrSubscribe(agencyId, (callback) => {
      if (callback) {
        const newOcrCount = localStorage.getItem("newOcrCount");
        const storedOcrCount = localStorage.getItem("storedOcrCount");
        const insertVal = parseInt(newOcrCount) + 1;
        setNewOcrCount(insertVal);
        localStorage.setItem("newOcrCount", `${insertVal}`);
        localStorage.setItem(
          "storedOcrCount",
          `${parseInt(storedOcrCount) + 1}`
        );
      }
    });
    const newOcrCount = localStorage.getItem("newOcrCount");
    setNewOcrCount(parseInt(newOcrCount));

    return () => {
      crUnsubscribe();
      ocrUnsubscribe();
    };
  }, [dispatch]);

  const navigate = (path) => {
    history.push(path);
  };

  const clearStore = () => {
    dispatch(clearStoreAction());
    dispatch(
      setCriteria({
        selectedCarrier: "",
        selectedAgency: "",
        selectedLevel: "",
        selectedPlanYear: "",
        selectedProducts: "",
        displayCounty: false,
        displayEffectiveData: false,
        displayState: false,
        displayWritingNumber: false,
      })
    );
    persistor.purge();
    sessionStorage.clear();
    client.clearStore();
    crUnsubscribe();
    ocrUnsubscribe();
    history.push("/login");
  };

  const { type } = props;

  const homeButton = (
    <LinkButtonWithTooltip pathName={`/`} title="Home" type="Dashboard" />
  );
  const agentsButton = (
    <LinkButtonWithTooltip
      pathName={`/AgentList`}
      title="My Agents"
      type="Group"
      onClick={() => dispatch(clearAgentListFilterModel())}
    />
  );
  const productionButton = (
    <LinkButtonWithTooltip
      data-tour="ad__production"
      pathName={`/Production`}
      title="Production Statistics"
      type="Business"
    />
  );

  const productionSummaryButton = (
    <LinkButtonWithTooltip
      data-tour="ad__productionSummary"
      pathName={`/ProductionStatisticsSummary`}
      title="Production Statistics Summary"
      type="DomainAdd"
    />
  );

  const contractRequestButton = (
    <LinkButtonWithTooltip
      onClick={() => setContractModalOpen(true)}
      title={
        <Typography>
          Make an Agent Contract Request
          <br></br>
          <br></br>
          Questions? We are here to help! Call our agent support team at
          1-888-745-2320
        </Typography>
      }
      type="ContractRequest"
    />
  );
  const exitButton = (
    <LinkButtonWithTooltip
      onClick={clearStore}
      pathname="/login"
      title="Logout"
      type="Logout"
    />
  );
  const recruiterShare = (
    <HtmlToolTip title="Onboard For Your Agency">
      <IconButton
        className={classes.shareButton}
        style={{ color: "white", outline: "none" }}
        onClick={() => setOpenShareAway(true)}
        size="small"
      >
        <Share fontSize="large" />
      </IconButton>
    </HtmlToolTip>
  );

  const shouldShowLeads = recruiterCode;
  const navButtons = shouldShowLeads
    ? [
        homeButton,
        agentsButton,
        productionButton,
        productionSummaryButton,
        contractRequestButton,
      ]
    : [
        homeButton,
        agentsButton,
        productionButton,
        productionSummaryButton,
        contractRequestButton,
      ];

  switch (type) {
    case "home":
      navButtons.splice(0, 1);
      break;
    case "agents":
      navButtons.splice(1, 1);
      break;
    case "production":
      navButtons.splice(2, 1);
      break;
    case "productionSummary":
      navButtons.splice(3, 1);
      break;
    case "requests":
      navButtons.splice(4, 1);
      break;
    default:
      break;
  }

  const recruiterMobileItem = {
    label: "Onboard For Your Agency",
    icon: <Share />,
    clickFunc: (e) => setOpenShareAway(true),
  };

  const leadsMobileItem = {
    label: "My Leads",
    icon: <GroupAdd />,
    clickFunc: () => navigate("/Leads"),
  };

  const mobileMenuItems = [
    {
      label: "Home",
      icon: <Dashboard />,
      clickFunc: () => navigate("/"),
    },
    {
      label: "My Agents",
      icon: <Group />,
      clickFunc: () => navigate("/AgentList"),
    },
    {
      label: "Production",
      icon: <Business />,
      clickFunc: () => navigate("/Production"),
    },
    {
      label: "Production Statistics Summary",
      icon: <DomainAdd />,
      clickFunc: () => navigate("/ProductionStatisticsSummary"),
    },
    {
      label: "Contract Request",
      icon: <HowToVoteOutlined />,
      clickFunc: () => setContractModalOpen(true),
    },
  ];
  // Splice in share button to show if recruiterCode exists
  // Add in at second to last position
  const spliceMobileItems = [...mobileMenuItems];
  spliceMobileItems.splice(mobileMenuItems.length - 1, 0, recruiterMobileItem);
  spliceMobileItems.splice(2, 0, leadsMobileItem);
  return (
    <>
      <ThemeProvider theme={theme}>
        <AppBar data-tour="ad__appbar" position="fixed" color="primary">
          <Toolbar style={{ width: "100%" }} disableGutters>
            <div className={classes.content}>
              <ButtonGroup size="small">
                {navButtons.map((button, i) => (
                  <span key={i}>{button}</span>
                ))}
              </ButtonGroup>
            </div>
            <div className={classes.mobileContent}>
              <MobileMenu
                mobileMenuItems={
                  shouldShowLeads ? spliceMobileItems : mobileMenuItems
                }
              />
            </div>
            <DashboardTitle />
            <Grid item xs={2} md={4} className={classes.exitButton}>
              {shouldShowLeads && recruiterShare}
              {exitButton}
            </Grid>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
      <ContractRequestModal
        contractModalOpen={contractModalOpen}
        handleClose={() => {
          setContractModalOpen(false);
        }}
      />
      <ShareAway
        openShareAway={openShareAway}
        closeModal={() => setOpenShareAway(false)}
      />
    </>
  );
};

export default NavBar;
