import {
  SET_PRODUCTION_STATISTICS_FOR_AGENCY_FULL,
  SET_PRODUCTION_STATISTICS_FOR_AGENCY_FULL_ERROR,
  CLEAR_PRODUCITON_STATISTICS_FOR_AGENCY_FULL,
  PRODUCTION_STATISTICS_FOR_AGENCY_FULL_LOADING,
  SET_PRODUCTION_STATISTICS_FOR_AGENCY_FULL_FILTER_MODEL,
  CLEAR_PRODUCTION_STATISTICS_FOR_AGENCY_FULL_FILTER_MODEL,
} from "../../constants/action-types";

import { getProductionStatisticsForAgencyFull } from "../../../services/GraphQl/BDSWebService";

export const setProductionStatisticsForAgencyFull = (data) => {
  return {
    type: SET_PRODUCTION_STATISTICS_FOR_AGENCY_FULL,
    payload: data,
  };
};

export const setProductionStatisticsForAgencyFullError = (error) => {
  return {
    type: SET_PRODUCTION_STATISTICS_FOR_AGENCY_FULL_ERROR,
    payload: error,
  };
};

export const clearProductionStatisticsForAgencyFull = () => {
  return {
    type: CLEAR_PRODUCITON_STATISTICS_FOR_AGENCY_FULL,
  };
};

export const setProductionStatisticsForAgencyFullLoading = (data) => {
  return {
    type: PRODUCTION_STATISTICS_FOR_AGENCY_FULL_LOADING,
    payload: data,
  };
};

export const setProductionStatisticsForAgencyFullFilterModel = (data) => {
  return {
    type: SET_PRODUCTION_STATISTICS_FOR_AGENCY_FULL_FILTER_MODEL,
    payload: data,
  };
};

export const clearProductionStatisticsForAgencyFullFilterModel = () => {
  return {
    type: CLEAR_PRODUCTION_STATISTICS_FOR_AGENCY_FULL_FILTER_MODEL,
  };
};

export const getProductionStatisticsForAgencyFullAction = (agencyId) => {
    return async (dispatch) => {
        dispatch(setProductionStatisticsForAgencyFullLoading(true));
        try {
        let ProResultData = await getProductionStatisticsForAgencyFull(agencyId);
        dispatch(setProductionStatisticsForAgencyFull(ProResultData));
        } catch (error) {
        dispatch(setProductionStatisticsForAgencyFullError(error));
        } finally {
        dispatch(setProductionStatisticsForAgencyFullLoading(false));
        }
    };
}

