import ProductionStatisticsSummaryMUI from "./ProductionStatisticsSummaryMUI";
import PageContainerWithMenu from "../../../containers/PageContainerWithMenu";
import ProductionStatisticsSummaryTotals from "./ProductionStatisticsSummaryTotals";
import PlainMenu from "../../PlainMenu";
import Box from "@mui/material/Box";

const ProductionStatisticsSummaryInfo = () => {
  return (
    <PageContainerWithMenu
      data-tour="ad__home"
      type="productionSummary"
      HasMenu={true}
      menu={
        <PlainMenu
          title="Production Statistics Summary"
          submenu={
            <div style={{ margin: "0 10px 0 10px" }}>
              <br />
              <Box display="flex" justifyContent="center" alignItems="center">
                <ProductionStatisticsSummaryTotals />
              </Box>
              <br />
              <br />
            </div>
          }
        />
      }
      content={<ProductionStatisticsSummaryMUI />}
    />
  );
};

export default ProductionStatisticsSummaryInfo;
