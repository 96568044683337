import { gql } from "apollo-boost";
import { fragments } from "./fragments";

export const getDashboardProductionGql = gql`
  query getDashboardProductionGql(
    $agencyId: Int!
    $year: Int!
    $hierLevel: Int
    $offset: Int
    $rowLimit: Int
  ) {
    getDashboardProduction(
      agencyId: $agencyId
      year: $year
      hierLevel: $hierLevel
      offset: $offset
      rowLimit: $rowLimit
    ) {
      carrier_id
      company_name
      agentname
      agent_id
      writing_number
      hlevel
      uplines
      effectivedate
      state
      county
      ma_production
      medsupp_production
      pdp_production
      ma_grandtotal
      medsupp_grandtotal
      pdp_grandtotal
      record_date
      npn
    }
  }
`;

export const getCarriersForCarrierIdsGql = gql`
  query getCarriersForCarrierIds($carrierIds: [String]) {
    getCarriersForCarrierIds(carrierIds: $carrierIds) {
      id
      name
      status
    }
  }
`;

export const getClrForUHCGql = gql`
  query getClrForUHC($agencyId: Int!, $carrierId: Int) {
    getClrForUHC(agencyId: $agencyId, carrierId: $carrierId) {
      agentName
      writingNumber
      upline
      effectiveDate
      dropDate
      receivedDate
      planProduct
      policy
      hicn
      carrierName
      memberName
    }
  }
`;

export const getCLRGql = gql`
  query getCLR($agencyId: Int!) {
    getCLR(agencyId: $agencyId) {
      agentName
      writingNumber
      upline
      effectiveDate
      dropDate
      receivedDate
      planProduct
      policy
      hicn
      carrierName
      memberName
    }
  }
`;

export const getAgencyStatusInfo = gql`
  query getAgencyStatusInfo($agencyId: Int!) {
    getAgencyStatusInfo(agencyId: $agencyId) {
      agencyId
      dashboardEnabled
    }
  }
`;

export const getAgentsInfoGql = gql`
  query getAgentsInfo {
    getAgentsInfo {
      tacygaId
      degreesFromBerwick
      agentId
      agentName
      npn
      agencyId
      agencyName
      principalId
      principalName
      subagencyId
      subagencyName
      subagencyPrincipalId
      subagencyPrincipalName
      agentLevel
      carrierId
      carrierName
      contractStatus
      agentCarrierId
      agentEmail
      agentPhone
      agentState
      agentUplines
      agentWayEnabled
    }
  }
`;

export const getAgentsContractInfo = gql`
  query getAgentsContractInfo($agencyId: Int!) {
    getAgentsContractInfo(agencyId: $agencyId) {
      tacygcId
      agentId
      agencyId
      carrierId
      agentCarrierId
      carrierName
      contractStatus
      agentLevel
      contractState
      writingNumber
      statusDate
    }
  }
`;

export const getAgentsReadyToSellInfo = gql`
  query getAgentsReadyToSellInfo($agencyId: Int!) {
    getAgentsReadyToSellInfo(agencyId: $agencyId) {
      tacygrtsId
      agencyId
      agentId
      writingNumber
      carrierId
      carrierName
      state
      product
      isCorporation
      agentCertified
      agentReady
      productReady
      planYear
    }
  }
`;

export const getProductionStatisticsGql = gql`
  query getProductionStatisticsHelper(
    $agencyId: Int!
    $year: Int!
    $hierLevel: Int
    $offset: Int
    $rowLimit: Int
    $product: String
    $orderBy: String
    $principalAgentId: Int
    $planChange: Boolean
  ) {
    getProductionStatisticsHelper(
      agencyId: $agencyId
      year: $year
      hierLevel: $hierLevel
      offset: $offset
      rowLimit: $rowLimit
      product: $product
      orderBy: $orderBy
      principalAgentId: $principalAgentId
      planChange: $planChange
    ) {
      agent_id
      agentname
      carrier_id
      carrier_name
      company_name
      county
      effectivedate
      hlevel
      ma_grandtotal
      ma_production
      medsupp_grandtotal
      medsupp_production
      name
      npn
      pdp_grandtotal
      pdp_production
      record_date
      state
      status
      uplines
      version
      writing_number
    }
  }
`;

export const refreshAdminLogin = gql`
  query refreshAdminLogin($username: String!, $refreshToken: String!) {
    refreshAdminLogin(username: $username, refreshToken: $refreshToken) {
      token
      refreshToken
    }
  }
`;

export const getAgencyEmailOwner = gql`
  query getAgencyEmailOwner($agencyId: String!) {
    getAgencyEmailOwner(agencyId: $agencyId) {
      adminEmail
    }
  }
`;

export const getAgencyInfoForBDSAdminAccount = gql`
  query getAgencyInfoForBDSAdminAccount($agencyId: String!) {
    getAgencyInfoForBDSAdminAccount(agencyId: $agencyId) {
      agencyId
      agencyName
      agentId
      agentTotal
      principals {
        agencyId
        agentId
      }
    }
  }
`;

export const getLatestETLDates = gql`
  query getLatestETLDates {
    getLatestETLDates {
      carrierId
      carrierName
      processType
      recordDate
    }
  }
`;

export const getContractStatuses = gql`
  query getContractStatuses($agencyId: String!) {
    getContractStatuses(agencyId: $agencyId) {
      total
      status
      effectivedate
    }
  }
`;

export const adminLogin = gql`
  query adminLogin($username: String!, $password: String!) {
    adminLogin(username: $username, password: $password) {
      token
      refreshToken
    }
  }
`;

export const refreshAgencyLogin = gql`
  query refreshAgencyLogin($username: String!, $refreshToken: String!) {
    refreshAgencyLogin(username: $username, refreshToken: $refreshToken) {
      token
      refreshToken
    }
  }
`;

export const agencyLogin = gql`
  query agencyLogin($username: String!, $password: String!) {
    agencyLogin(username: $username, password: $password) {
      token
      refreshToken
      agencyInfo {
        agencyId
        agencyName
        agentId
        agentTotal
      }
    }
  }
`;

export const sendContractRequest = gql`
  mutation sendContractRequestv2($input: contractRequestEmailInput!) {
    sendContractRequestV2(input: $input)
  }
`;

export const certsPerAgentDash = gql`
  query certsPerAgentDash($agentId: String!, $agencyId: String!) {
    certsPerAgentDash(agentId: $agentId, agencyId: $agencyId) {
      rtsCertsId
      contractId
      npn
      writingNumber
      isCertified
      isReady
      planYear
      carrierId
      isCorporation
      agentId
      name
      products {
        product
        certified
        state
        ready
        carrierNote
      }
    }
  }
`;

export const getSubAgenciesForCarrier = gql`
  query getSubAgenciesForCarrier($agencyId: String!, $carrierId: String!) {
    getSubAgenciesForCarrier(agencyId: $agencyId, carrierId: $carrierId) {
      agentId
      agentLevel
      name
    }
  }
`;

export const getSubAgencies = gql`
  query getSubAgencies($agencyId: String!) {
    getSubAgencies(agencyId: $agencyId) {
      agentId
      agentLevel
      name
    }
  }
`;

export const getCurrentProductionYear = gql`
  query getCurrentProductionYear {
    getCurrentProductionYear {
      currentproduction
    }
  }
`;

export const getLevelsForCarrier = gql`
  query getLevelsForCarrier($agentId: String!, $carrierId: String!) {
    getLevelsForCarrier(agentId: $agentId, carrierId: $carrierId) {
      agentLevelId
      carrierLabel
    }
  }
`;

export const getSubAgenciesFor1904 = gql`
  query getSubAgenciesFor1904 {
    getSubAgenciesFor1904 {
      agencyId
    }
  }
`;

export const getPlanChangeCarriers = gql`
  query getPlanChangeCarriers {
    getPlanChangeCarriers {
      id
      name
    }
  }
`;

export const getStates = gql`
  query getStates {
    getStates {
      name
      code
    }
  }
`;

export const getAgents = gql`
  query getAgents($agencyId: String!) {
    getAgents(agencyId: $agencyId) {
      agentId
      npn
      firstname
      lastname
      gender
      dob
      state
      phone
      email
      lisence
    }
  }
`;
export const getAllCarriers = gql`
  query getAllCarriers {
    getAllCarriers {
      name
      id
    }
  }
`;

export const sendEmailApi = gql`
  mutation sendEmailApi(
    $from: String!
    $to: [String]!
    $cc: [String]
    $bcc: [String]
    # $replyTo: String
    $subject: String
    $body: String
    $attachments: [EmailAttachment]
    $save: Boolean
  ) {
    sendEmailApi(
      from: $from
      to: $to
      cc: $cc
      bcc: $bcc
      subject: $subject
      body: $body
      attachments: $attachments
      save: $save
    ) {
      success
    }
  }
`;

export const pushNotification = gql`
  mutation pushNotification($input: AwayNotificationInput!) {
    pushNotification(input: $input) {
      id
      subject
      body
      type
    }
  }
`;

export const awayNotifications = gql`
  query awayNotifications($paging: PagingInput, $filters: [FilterInput]) {
    awayNotifications(paging: $paging, filters: $filters) {
      totalCount
      data {
        __typename
        id
        subject
        body
        agencyId
        createdById
        agentIds {
          agentId
          status
          seen
        }
        createDate
        agents {
          id
          displayName
        }
        type
      }
    }
  }
`;

export const isAdminTokenValid = gql`
  query isAdminTokenValid {
    isAdminTokenValid
  }
`;

export const isAgencyTokenValid = gql`
  query isAgencyTokenValid {
    isAgencyTokenValid
  }
`;

export const getCertsPerCarrier = gql`
  query getCertsPerCarrier($agencyId: ID) {
    getCertsPerCarrier(agencyId: $agencyId) {
      carrierId
      name
      count
      planYear
    }
  }
`;

export const getCertsTable = gql`
  query getCertsTable($agencyId: ID!) {
    getCertsTable(agencyId: $agencyId) {
      agentName
      email
      planYear
      carrierName
      carrierId
      isCorporation
      isReady
      state
      certified
      ready
      product
      npn
    }
  }
`;

export const agentHierarchyWithIds = gql`
  query agentHierarchyWithIds($agentId: String!, $agencyId: String!) {
    agentHierarchyWithIds(agentId: $agentId, agencyId: $agencyId) {
      carrierName
      degreesFromBerwick
      uplineString
      agencyList
    }
  }
`;

export const contractRequestsAgency = gql`
  query contractRequestsAgency($agencyId: String!) {
    contractRequestsAgency(agencyId: $agencyId) {
      ...ContractRequestData
    }
  }
  ${fragments.contractRequestData}
`;

export const contractRequestModified = gql`
  subscription contractRequestModified($agencyId: String) {
    contractRequestModified(agencyId: $agencyId)
  }
`;

export const onboardContractRequestInsert = gql`
  subscription onboardContractRequestInsert($agencyId: String) {
    onboardContractRequestInsert(agencyId: $agencyId)
  }
`;

export const agencyContractRequest = gql`
  mutation agencyContractRequest($input: AgencyContractRequestInput!) {
    agencyContractRequest(input: $input)
  }
`;

export const sendDashEmailAws = gql`
  mutation sendDashEmailAws($input: AWSSendEmailInput) {
    sendDashEmailAws(input: $input) {
      success
    }
  }
`;

export const updateAgencyContractRequest = gql`
  mutation updateAgencyContractRequest(
    $input: UpdateAgencyContractRequestInput
  ) {
    updateAgencyContractRequest(input: $input)
  }
`;

export const agencyAgentUserInfo = gql`
  query agencyAgentUserInfo($agentIds: [String]) {
    agencyAgentUserInfo(agentIds: $agentIds) {
      id
      loginDate
    }
  }
`;

export const recruiterInfoById = gql`
  query recruiterInfoById($id: String!) {
    recruiterInfoById(id: $id) {
      recruiterCode
      recruiterEmail
      recruiterPhone
    }
  }
`;

export const onboardLeadsByRecruiter = gql`
  query onboardLeadsByRecruiter($recruiterId: String!) {
    onboardLeadsByRecruiter(recruiterId: $recruiterId) {
      onboardId
      firstName
      lastName
      email
      phone
      recruiterId
      displayName
      npn
      gender
      residentState
      birthDate
      ssn
      createDate
      onboardContractRequests {
        ocrId
        carrierName
        statesRequested
        onboardId
        recruiterId
        createDate
        isAsCorp
        licenseNumber
        status
        upline
        level
        corpTin
        carriernameId
        corpName
        corpNpn
        isAsIndividual
        notes
      }
    }
  }
`;

export const updateOnboardLead = gql`
  mutation updateOnboardLead($input: UpdateOnboardLeadInput) {
    updateOnboardLead(input: $input)
  }
`;

export const updateOcr = gql`
  mutation updateOcr($input: UpdateOnboardContractRequestInput) {
    updateOcr(input: $input)
  }
`;

export const ocrUpdateStatus = gql`
  mutation ocrUpdateStatus($idArr: [Int], $status: String) {
    ocrUpdateStatus(idArr: $idArr, status: $status)
  }
`;

export const createAgentLead = gql`
  mutation createAgentLead($agentLeadInput: AgentLeadInput) {
    createAgentLead(agentLeadInput: $agentLeadInput)
  }
`;

export const updateRecruiter = gql`
  mutation updateRecruiter($input: UpdateRecruiterInput) {
    updateRecruiter(input: $input)
  }
`;

export const validOcrsCountByAgencyId = gql`
  query validOcrsCountByAgencyId($agencyId: String) {
    validOcrsCountByAgencyId(agencyId: $agencyId) {
      count
    }
  }
`;

export const getCalc = gql`
  query pdpCalc($eligibleDate: Date!, $enrollmentDate: Date!) {
    pdpCalc(eligibleDate: $eligibleDate, enrollmentDate: $enrollmentDate)
  }
`;

export const getAgencyProductionByCarrierAndProduct = gql`
  query getAgencyProductionByCarrierAndProduct($agencyId: String!) {
    getAgencyProductionByCarrierAndProduct(agencyId: $agencyId) {
      carrierName
      product
      sum
      salesYear
    }
  }
`;

export const getProductionByCarrierAndMonth = gql`
  query getProductionByCarrierAndMonth($agencyId: String!, $product: String!) {
    getProductionByCarrierAndMonth(agencyId: $agencyId, product: $product) {
      carrierId
      carrierName
      month
      monthName
      y2022
      y2023
      y2024
    }
  }
`;

export const getProductionByCarrierAndQtr = gql`
  query getProductionByCarrierAndQtr($agencyId: String!) {
    getProductionByCarrierAndQtr(agencyId: $agencyId) {
      carrierId
      carrierName
      quarter
      product
      Year1
      Year2
      Year3
    }
  }
`;

export const getAgencyStatsProfileOverYears = gql`
  query getAgencyStatsProfileOverYears($agencyId: Int!) {
    getAgencyStatsProfileOverYears(agencyId: $agencyId) {
      agencyId
      agentName
      agentId
      companyName
      carrierName
      carrierId
      writingNumber
      npn
      planYear
      ma
      medsupp
      pdp
      ifp
      state
      county
      effectivedate
      hlevel
      uplines
    }
  }
`;

export const getCarriers = gql`
  query getCarriers {
    getCarriers {
      id
      name
    }
  }
`;

export const getLatestProductionSalesYear = gql`
  query getLatestProductionSalesYear {
    getLatestProductionSalesYear {
      salesYear
    }
  }
`;

export const cognitoForgotPassword = gql`
  query cognitoForgotPassword($username: String!, $poolKey: String!) {
    cognitoForgotPassword(username: $username, poolKey: $poolKey)
  }
`;

export const cognitoConfirmPassword = gql`
  query cognitoConfirmPassword(
    $username: String!
    $verificationCode: String!
    $newPass: String!
    $poolKey: String!
  ) {
    cognitoConfirmPassword(
      username: $username
      verificationCode: $verificationCode
      newPass: $newPass
      poolKey: $poolKey
    )
  }
`;

export const getProductionStatisticsForAgency = gql`
  query getProductionStatisticsForAgency($agencyId: ID!) {
    getProductionStatisticsForAgency(
      agencyId: $agencyId
    ) {
        agencyId
        agentId
        agentName
        carrierName
        carrierId
        salesYear
        agentCarrierMaGrandTotal
        agentCarrierMedsuppGrandTotal
        agentCarrierPdpGrandTotal
    }
}
`;

export const getProductionStatisticsForAgencyFull = gql`
  query getProductionStatisticsForAgencyFull($agencyId: ID!) {
    getProductionStatisticsForAgencyFull(agencyId: $agencyId) {
        tadstatsProductionId
        agencyId
        carrierName
        carrierId
        companyName
        agentId
        writingNumber
        hlevel
        sortOrder
        uplines
        effectivedate
        state
        county
        ma
        medsupp
        pdp
        agentCarrierMaGrandTotal
        agentCarrierMedsuppGrandTotal
        agentCarrierPdpGrandTotal
        carrierMaGrandTotal
        carrierMedsuppGrandTotal
        carrierPdpGrandTotal
        recordDate
        npn
        isFinal
        salesYear
        ifp
        agentCarrierIfpGrandTotal
        carrierIfpGrandTotal        
        maTotal
        medsuppTotal
        pdpTotal
        agentName
    }
  }
`;

export const updateAgencyDashboardEnabled = gql`
  mutation updateAgencyDashboardEnabled(
    $agencyId: Int!
    $dashboardEnabled: Boolean!
  ) {
    updateAgencyDashboardEnabled(
      agencyId: $agencyId
      dashboardEnabled: $dashboardEnabled
    )
  }
`;

export const registerValidationEmail = gql`
  query registerValidationEmail($recipient: String, $validationCode: String) {
    registerValidationEmail(
      recipient: $recipient
      validationCode: $validationCode
    )
  }
`;

export const addOrUpdateCognitoAgency = gql`
  query addOrUpdateCognitoAgency($options: CreateUpdateAgencyOptions) {
    addOrUpdateCognitoAgency(options: $options) {
      success
    }
  }
`;
