import { Box, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import LineChartHeader from "./LineChartHeader";
import LineChartBody from "./LineChartBody";

const LineChartComponent = (props) => {
  const {
    productionByCarrierAndQtr,
    productionByCarrierAndQtrLoading,
    productionSalesYear,
  } = useSelector((state) => state.productionLineGraph);

  return (
    <div>
      {productionByCarrierAndQtrLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="48vh"
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <div style={{ paddingBottom: '10px' }}>
          <LineChartHeader data={productionSalesYear} />
          <LineChartBody data={productionByCarrierAndQtr} />
        </div>
      )}
    </div>
  );
};

export default LineChartComponent;
