import React, { useState } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import AgentProductionColumns from "./AgentProductionColumns";
import AgentProductionData from "./AgentProductionData";
import StripedDataGridTemplate from "../../../DataGridTemplate/StripedDataGridTemplate";
import DataGridNoResults from "../../../DataGridTemplate/DataGridNoResults";
import _ from "lodash";
import classes from "../../../DataGridTemplate/DataGridTemplate.module.css";

const AgentProductionInfo = (props) => {
  const { agentId } = props;
  const data = useSelector(
    (state) => state.productionStatisticsForAgency.dataSummary
  );
  const getMaxYear = () => {
    if (data.length > 0) {
      return Math.max(...data.map((row) => row.salesYear));
    }
    return 0;
  };
  const maxYear = getMaxYear();

  const getFilterModel = () => {
    return {
      items: [
        {
          id: 0,
          field: "salesYear",
          operator: "equals",
          value: maxYear.toString(),
        },
      ],
    };
  };

  const [filterModel, setFilterModel] = useState(getFilterModel());
  const isLoading = false;
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });

  const columns = AgentProductionColumns();
  const uniqueRows =
    maxYear !== 0
      ? AgentProductionData({
          data: data,
          agentId: agentId,
          currentYear: maxYear,
        })
      : [];
  const sxToolBarContainer = { fontSize: 12, paddingBottom: 1 };
  const sxToolBarColumnsButton = {
    color: "black",
    backgroundColor: "whitesmoke",
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={sxToolBarContainer}>
        <GridToolbarFilterButton sx={sxToolBarColumnsButton} />
        <GridToolbarExport sx={sxToolBarColumnsButton} />
      </GridToolbarContainer>
    );
  };

  const StripedDataGrid = StripedDataGridTemplate();

  const sxBox = {
    height: "auto",
    width: "100%",
    "& .super-app-theme--header": {
      backgroundColor: "rgba(197, 5, 157, 0.47)",
      borderBottom: "1px solid rgba(27, 133, 243, 0.8)",
    },
  };

  const getRowClass = (params) => {
    if (params.row.carrierName === "Total") {
      return "bold";
    }
    return params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "";
  };

  const debouncedSetFilterModel = _.debounce((newFilterModel) => {
    setFilterModel(newFilterModel);
  }, 1000);

  const StripedDataGridComponent = () => (
    <StripedDataGrid
      slots={{ toolbar: CustomToolbar }}
      loading={isLoading}
      rows={uniqueRows}
      columns={columns}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      pageSizeOptions={[5, 10, 25]}
      pagination={true}
      disableRowSelectionOnClick
      getRowClassName={getRowClass}
      filterModel={filterModel}
      onFilterModelChange={(newFilterModel) => {
        debouncedSetFilterModel(newFilterModel);
      }}
      density="compact"
      autoHeight
    />
  );

  const StripedDataGridNoResults = () => {
    return <DataGridNoResults columns={columns} uniqueRows={uniqueRows} />;
  };

  return (
    <>
      {(!uniqueRows || uniqueRows.length === 3) && (
        <div className={classes.myClass}>
          <Box sx={sxBox}>
            <StripedDataGridNoResults />
          </Box>
        </div>
      )}
      {uniqueRows &&
        uniqueRows.length > 3 &&
        uniqueRows[0].carrierName !== "" && (
          <div className={classes.myClass}>
            <Box sx={sxBox}>
              <StripedDataGridComponent />
            </Box>
          </div>
        )}
    </>
  );
};

export default AgentProductionInfo;
