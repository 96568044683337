const AgentProductionData = (props) => {
  const { data, agentId, currentYear } = props;
  const uniqueRows = [];
  const dataRows = data
    ? data
        .filter((row) => row.agentId === agentId)
        .map((row) => ({
          id: row.agencyId + row.agentId + row.carrierId + row.salesYear,
          carrierName: row.carrierName,
          maTotal: row.agentCarrierMaGrandTotal,
          medsuppTotal: row.agentCarrierMedsuppGrandTotal,
          pdpTotal: row.agentCarrierPdpGrandTotal,
          salesYear: row.salesYear,
        })).sort((a, b) => a.carrierName.localeCompare(b.carrierName))
    : [];

  const calculateProduction = (id, salesYear) => {
    let maTotal = 0;
    let medsuppTotal = 0;
    let pdpTotal = 0;
    const carrierName = "Total";

    uniqueRows.forEach((row) => {
      if (row.salesYear === salesYear) {
        maTotal += parseInt(row.maTotal);
        medsuppTotal += parseInt(row.medsuppTotal);
        pdpTotal += parseInt(row.pdpTotal);
      }
    });

    return {
      id,
      carrierName,
      maTotal,
      medsuppTotal,
      pdpTotal,
      salesYear,
    };
  };

  dataRows.forEach((row) => {
    if (!uniqueRows.find((existingRow) => existingRow.id === row.id)) {
      uniqueRows.push(row);
    }
  });

  uniqueRows.push(calculateProduction("currentYear", parseInt(currentYear)));
  uniqueRows.push(
    calculateProduction("previousYear", parseInt(currentYear) - 1)
  );
  uniqueRows.push(
    calculateProduction("twoYearsAgo", parseInt(currentYear) - 2)
  );
 
  return uniqueRows;
};

export default AgentProductionData;
