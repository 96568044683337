import TabAgentTileInfo from "./components/TabAgentTileInfo"

const AgentTileInfo = (props) => {
  const { agentId, agentsContractInfoData } = props;
  return (
    <TabAgentTileInfo
      agentId={agentId}
      agentsContractInfoData={agentsContractInfoData}      
    />
  );
}

export default AgentTileInfo;