import {
  SET_CURRENT_PRODUCTION,
  SET_PRODUCTION_ERROR,
  CURRENT_PRODUCTION_LOADING,
  SET_PRODUCTION_GRID_DATA,
  CLEAR_PRODUCTION_GRID_DATA,
  PRODUCTION_GRID_DATA_LOADING,
  SET_PRODUCTION_GRID_DATA_ERROR,
} from "../../constants/action-types";
import {
  getCurrentProductionYear,
  getAvailablePlanYears,
  getAgencyStatsProfileOverYears,
  getProductionStatisticsGql,
} from "../../../services/GraphQl/BDSWebService";
import { GetTopsFromCarriers } from "../../../utils/ProductionUtils";

/* redux-thunk implementation */
export const setCurrentProduction = (agencyId, year) => {
  return async (dispatch, getState) => {
    dispatch({
      type: CURRENT_PRODUCTION_LOADING,
      payload: true,
    });
    
    let currentProductionYear = sessionStorage.getItem("currentProductionYear");
    if (!currentProductionYear) {
      currentProductionYear = await getCurrentProductionYear();
      sessionStorage.setItem("currentProductionYear", currentProductionYear);
    }
    let availablePlanYears = sessionStorage.getItem("availablePlanYears");
    if (!availablePlanYears) {
      availablePlanYears = await getAvailablePlanYears();
      sessionStorage.setItem("availablePlanYears", availablePlanYears);
    } else {
      availablePlanYears = availablePlanYears.split(",");
    }
    if (agencyId === year) {
      year = currentProductionYear;

    }
    if (!year) {
      year = currentProductionYear;
    }

    let ProResultData = undefined;
    let ProResult = undefined;

    for (const element of availablePlanYears) {
      if (element === year.toString()) {
        ProResultData = await getProductionStatisticsGql(
          parseInt(agencyId),
          parseInt(element.toString())
        );

        ProResult = ProResultData.data
          ? ProResultData.data.getProductionStatisticsHelper
          : ProResultData;

        if (ProResult.length > 0) {
          year = element;
          break;
        }
      }
    }

    let carriersDyn = [];
    ProResult.forEach((value, index) => {
      if (
        carriersDyn
          .map(
            (e) =>
              e.id === value.carrier_id.toString() &&
              e.name === value.carrier_name
          )
          .includes(true)
      ) {
        //do nothing because the carrier is already in the array
      } else {
        carriersDyn.push({
          id: value.carrier_id.toString(),
          name: value.carrier_name,
        });
      }
    });

      carriersDyn = carriersDyn.sort((a, b) => {
      let carrierNameA = a.name;
      let carrierNameB = b.name;

      if (carrierNameA < carrierNameB) {
        return -1;
      } else if (carrierNameA > carrierNameB) {
        return 1;
      } else {
        return 0;
      }
    });

    try {
      if (carriersDyn.length < 1) {
        sessionStorage.setItem("HasProduction", false);
        dispatch(
          setCurrentProductionResults({
            currentYear: currentProductionYear,
            availablePlanYears: availablePlanYears,
            production: undefined,
          })
        );
      } else if (!Array.isArray(carriersDyn)) {
        throw new Error("can't retrieve");
      } else {
        let ma_total = 0;
        let ms_total = 0;
        carriersDyn.forEach((carrier) => {
          carrier.result = ProResult.filter(
            (item) => item.carrier_id.toString() === carrier.id
          );
          var carrierMaTotal = 0;
          var carrierMsTotal = 0;
          var carrierPdpTotal = 0;
          carrier.result.forEach((row) => {
            ma_total += row.ma_production;
            ms_total += row.medsupp_production;
            carrierMaTotal += row.ma_production;
            carrierMsTotal += row.medsupp_production;
            carrierPdpTotal += row.pdp_production;
          });
          carrier.ma_total = carrierMaTotal;
          carrier.ms_total = carrierMsTotal;
          carrier.pdp_total = carrierPdpTotal;
        });

        const tops = GetTopsFromCarriers(carriersDyn);

        let AllProductionData = getState().currentProduction.production || [];
        AllProductionData[year] = {
          data: carriersDyn,
          ma_total: ma_total,
          ms_total: ms_total,
          ma_top: tops.ma_top,
          ma_top_total: tops.ma_top_total,
          ms_top: tops.ms_top,
          ms_top_total: tops.ms_top_total,
        };
        dispatch(
          setCurrentProductionResults({
            currentYear: year,
            availablePlanYears: availablePlanYears,
            production: AllProductionData,
          })
        );
      }
    } catch (error) {
      dispatch({
        type: SET_PRODUCTION_ERROR,
        payload: error,
      });
    } finally {
      dispatch({
        type: CURRENT_PRODUCTION_LOADING,
        payload: false,
      });
    }
  };
};

/* redux action */
const setCurrentProductionResults = (data) => {
  return {
    type: SET_CURRENT_PRODUCTION,
    payload: data,
  };
};

export const setProductionGridData = (data) => {
  return {
    type: SET_PRODUCTION_GRID_DATA,
    payload: data,
  };
};

export const clearProductionGridData = () => {
  return {
    type: CLEAR_PRODUCTION_GRID_DATA,
  };
};

export const setProductionGridDataLoading = (data) => {
  return {
    type: PRODUCTION_GRID_DATA_LOADING,
    payload: data,
  };
};

export const setProductionGridDataError = (error) => {
  return {
    type: SET_PRODUCTION_GRID_DATA_ERROR,
    payload: error,
  };
};

export const fetchProductionGridData = (agencyId) => {
  return async (dispatch) => {
    dispatch(setProductionGridDataLoading(true));
    try {
      let ProResultData = await getAgencyStatsProfileOverYears(
        parseInt(agencyId)
      );
      let ProResult = ProResultData.data
        ? ProResultData.data.getAgencyStatsProfileOverYears
        : ProResultData;
      dispatch(setProductionGridData(ProResult));
    } catch (error) {
      dispatch(setProductionGridDataError(error));
    } finally {
      dispatch(setProductionGridDataLoading(false));
    }
  };
};
