const ProductionStatisticsData = (props) => {
  const { data } = props;
  const uniqueRows = [];
  const dataRows = data?.map((row) => {
    const effectiveDate = new Date(Number(row.effectivedate));
    const formattedDate = effectiveDate.toLocaleDateString();
    return {
      id: row.tadstatsProductionId,
      agentName: row.agentName,
      carrierName: row.carrierName,
      companyName: row.companyName,
      hlevel: row.hlevel,
      maTotal: row.maTotal,
      medsuppTotal: row.medsuppTotal,
      pdpTotal: row.pdpTotal,
      salesYear: row.salesYear,
      uplines: row.uplines,
      county: row.county,
      state: row.state,
      effectivedate: formattedDate,
    };
  });

  dataRows.forEach((row) => {
    if (!uniqueRows.find((existingRow) => existingRow.id === row.id)) {
      uniqueRows.push(row);
    }
  });

  return uniqueRows;
};

export default ProductionStatisticsData;
