import React from "react";
import { makeStyles } from "tss-react/mui";
import { Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import "./LandingPage.css";
import history from "../utils/history";

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      flexGrow: 1,
    },
    BannerStart: {
      color: "#FFFFFF",
      fontSize: "calc(24px + (36 - 24) * ((100vw - 250px) / (4000 - 250)))",
      lineHeight: "calc(1.3em + (1.5 - 1.2) * ((100vw - 250px)/(3000 - 250)))",
      fontFamily: "mazBold",
    },
    BannerEnd: {
      color: "#FFFFFF",
      fontSize: "calc(24px + (36 - 24) * ((100vw - 250px) / (4000 - 250)))",
      lineHeight: "calc(1.3em + (1.5 - 1.2) * ((100vw - 250px)/(3000 - 250)))",
      fontFamily: "maz",
    },
    home: {
      color: "#4EAEF5",
    },
    pageTitle: {
      wordWrap: "no-wrap",
    },
    divider: {
      height: 30,
      width: 3,
      backgroundColor: "grey",
    },
  };
});

const DashboardTitle = (props) => {
  const { classes } = useStyles();
  const HeaderView = () => {
    const title = window.location.pathname;
    switch (title) {
      case "/":
        return " Agency Overview ";
      case "/AgentList":
        return " My Agents ";
      case "/Production":
        return " Production Statistics ";
      case "/ProductionStatisticsSummary":
        return " Production Statistics Summary ";
      default:
        return null;
    }
  };
  const mQuery = useMediaQuery("(max-width:1200px)");

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      className={classes.root}
    >
      <Grid item>
        <Grid
          container
          justifyContent="center"
          data-tour="ad__dashboardTitle"
          direction="row"
          wrap="nowrap"
          onClick={() => history.push("/")}
          style={{ cursor: "pointer" }}
        >
          <Typography
            className={classes.BannerStart}
            style={{ fontWeight: "bolder" }}
          >
            AGENCY
          </Typography>
          <Typography
            className={classes.BannerEnd}
            style={{ fontWeight: "lighter" }}
          >
            DASHBOARD
          </Typography>
        </Grid>
      </Grid>
      {!mQuery && (
        <>
          <Grid item>
            <Divider orientation="vertical" className={classes.divider} />
          </Grid>
          <Grid item>
            <Typography
              variant="h5"
              component="h2"
              className={classes.pageTitle}
              noWrap
            >
              {HeaderView()}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default DashboardTitle;
