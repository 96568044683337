import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Switch, Typography } from "@mui/material";
import { setProductionStatisticsForAgencyFullFilterModel } from "../../../store/actions/production/productionStatisticsForAgencyFull";
import { setPlanChangeFilters } from "../../../store/actions/plan-change/filteredPlanChangeTotals";
import ProductionStatisticsMUI from "./ProductionStatisitcsMUI";
import PlanChangeProductionTable from "../PlanChanges/PlanChanges";
import PageContainerWithMenu from "../../../containers/PageContainerWithMenu";
import ProductionStatisticsSummaryTotals from "../ProductionStatisticsSummary/ProductionStatisticsSummaryTotals";
import PlainMenu from "../../PlainMenu";
import Box from "@mui/material/Box";
import { GridLogicOperator } from "@mui/x-data-grid-pro";
import { makeStyles } from "tss-react/mui";
import ProductionDataByCarrier from "../../shared/ProductionDataByCarrier";
import moment from "moment";

export const ProductionStatisticsInfo = (props) => {
  const dispatch = useDispatch();
  const planChangeData = useSelector((state) => state.planChange.production);
  const productionData = useSelector(
    (state) => state.productionStatisticsForAgencyFull.dataSummary
  );
  const [productionStatisticsToggle, setProductionStatisticsToggle] =
    useState(true);

  const etlDates = useSelector((state) => state.production.etlDates);

  const currentYear = useSelector(
    (state) => state.currentProduction.currentYear
  );
  const planYear = props.location.state
    ? props.location.state.state?.planYear ?? props.location.state.planYear
    : currentYear.toString();
  const carrierName = props.location.state
    ? props.location.state.state?.carrierName ?? props.location.state.carrier
    : undefined;

  const uniqueProductionCarriers = () => {
    let results = [];
    const filteredData = productionData.filter(
      (item) => item.salesYear === parseInt(currentYear));
    if (productionStatisticsToggle === true) {
      results = [...new Set(filteredData.map((item) => item.carrierId.toString()))];
    } else {
      results = [
        ...new Set(planChangeData.map((item) => item.carrier_id.toString())),
      ];
    }
    return results;
  };

  const carrierReportDates = [];
  const productionDataFiltered = uniqueProductionCarriers();

  productionDataFiltered.forEach((carrier) => {
    const carrierDateRow = etlDates.filter(
      (item) =>
        item.carrierId.toString() === carrier.toString() &&
        item.processType ===
          (productionStatisticsToggle ? "production" : "planChange production")
    );

    if (carrierDateRow.length > 0) {
      carrierReportDates.push({
        carrierName: carrierDateRow[0].carrierName,
        ETLDate: moment.utc(carrierDateRow[0].recordDate).format("MM-DD-YYYY"),
      });
    }
  });

  if (!productionStatisticsToggle) {
    dispatch(
      setPlanChangeFilters({
        items: [
          {
            id: 0,
            field: "salesYear",
            operator: "equals",
            value: currentYear.toString(),
          },
        ],
      })
    );
  }

  if (planYear || carrierName) {
    let tempFilterModelItems = [];

    tempFilterModelItems.push({
      id: 0,
      field: "salesYear",
      operator: "equals",
      value: planYear,
    });

    if (planYear && carrierName) {
      tempFilterModelItems.push({
        id: 1,
        field: "carrierName",
        operator: "equals",
        value: carrierName,
      });
      dispatch(
        setProductionStatisticsForAgencyFullFilterModel({
          items: tempFilterModelItems,
          logicOperator: GridLogicOperator.And,
        })
      );
    } else {
      dispatch(
        setProductionStatisticsForAgencyFullFilterModel({
          items: tempFilterModelItems,
        })
      );
    }
  }

  const useStyles = makeStyles()((theme) => {
    return {
      switchContainer: {
        color: "rgb(0, 55, 96)",
        backgroundColor: "white",
        padding: "0px 12px",
        borderRadius: 5,
        marginBottom: 0,
        alignItems: "center",
        justifyContent: "center",
        marginRight: 0,
        width: "100%",
        "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
          backgroundColor: "rgb(0, 55, 96)",
          opacity: 0.75,
        },
        "& .MuiSwitch-colorSecondary": {
          color: "rgb(0, 55, 96)",
        },
        "& .MuiSwitch-input": { display: "flex" },
      },
    };
  });

  const { classes } = useStyles();

  return (
    <PageContainerWithMenu
      data-tour="ad__home"
      type="production"
      HasMenu={true}
      menu={
        <PlainMenu
          title="Production"
          submenu={
            <div style={{ margin: "0 10px 0 10px" }}>
              <Grid
                container
                alignItems="center"
                className={classes.switchContainer}
              >
                <Grid item>
                  <Typography variant="body1">Production Statistics</Typography>
                </Grid>
                <Grid item>
                  <Switch
                    checked={!productionStatisticsToggle}
                    onChange={() =>
                      setProductionStatisticsToggle(!productionStatisticsToggle)
                    }
                  />
                </Grid>
                <Grid item>
                  <Typography variant="body1">Plan Change</Typography>
                </Grid>
              </Grid>
              <br />
              <br />
              <Box display="flex" justifyContent="center" alignItems="center">
                <ProductionStatisticsSummaryTotals
                  productionStatisticsToggle={productionStatisticsToggle}
                />
              </Box>
              <br />
              <br />
              <Box display="flex" justifyContent="center" alignItems="center">
                <ProductionDataByCarrier
                  data={carrierReportDates.toSorted((a, b) =>
                    a.carrierName.localeCompare(b.carrierName)
                  )}
                />
              </Box>
            </div>
          }
        />
      }
      content={
        productionStatisticsToggle ? (
          <ProductionStatisticsMUI
            planYear={planYear}
            carrierName={carrierName}
            productionData={productionData}
          />
        ) : (
          <PlanChangeProductionTable />
        )
      }
    />
  );
};

export default ProductionStatisticsInfo;
