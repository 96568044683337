import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import "./LandingPage.css";
import { getAgency } from "../store/actions/index";
import { setCurrentProduction } from "../store/actions/production/CurrentProduction";
import { setPlanChange } from "../store/actions/plan-change/PlanChange";
import { setETLDates } from "../store/actions/production/productionActions";
import LoadingOverlay from "@ronchalant/react-loading-overlay";
import history from "../utils/history";
import { getProductionStatisticsForAgencyAction } from "../store/actions/production/productionStatisticsForAgency";
import { getProductionStatisticsForAgencyFullAction } from "../store/actions/production/productionStatisticsForAgencyFull";
import {
  fetchReadyToSellList,
} from "../store/actions/agents/readyToSell";
import { fetchAgentContractList } from "../store/actions/agents/agentsContractInfo";
import {
  fetchAgentReadyToSellInfo,
} from "../store/actions/agents/agentsReadyToSellInfo";
import { fetchAgentList } from "../store/actions/agents/agents";
import { fetchProductionGridData } from "../store/actions/production/CurrentProduction";
import { setContractStatuses } from "../store/actions/contracts";
import { fetchAgentReadyToSellByCarrier } from "../store/actions/agents/readyToSellByCarrier.js";
import {
  fetchProductionCarrierAndQtrData,
  fetchProductionSalesYear,
} from "../store/actions/production/productionLineGraph";

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      textAlign: "center",
      margin: "0px",
      display: "grid",
      webkitBackgroundSize: "cover",
      mozBackgroundSize: "cover",
      // backgroundSize: "cover",
      backgroundSize: "16px 16px",
      minHeight: "100vh",
      background: "#424242",
      backgroundColor: "rgb(68, 66, 66)",
      paddingBottom: 20,
    },
    overlay: {
      height: "100%",
    },
  };
});

const LandingPage = () => {
  const [loaderText, setLoaderText] = useState("Configuring Agency...");
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const currentProduction = useSelector(
    ({ currentProduction }) => currentProduction.currentProduction
  );
  const planChange = useSelector(({ planChange }) => planChange.production);
  const FetchingProductionError = useSelector(
    ({ currentProduction }) => currentProduction.FetchingProductionError
  );
  const FetchingPlanChangeError = useSelector(
    ({ planChange }) => planChange.FetchingPlanChangeError
  );

  let isDataLoaded = currentProduction?.currentYear && planChange !== undefined;

  const loadWithError =
    FetchingProductionError !== undefined ||
    FetchingPlanChangeError !== undefined;

  if (loadWithError) {
    alert("Data loaded with error , please refresh ");
    isDataLoaded = true;
  }

  const navigate = useCallback((path) => {
    history.push(path);
  }, []);

  const LoadInitData = useCallback(() => {
    setTimeout(() => setLoaderText("Gathering Data..."), 1500);
    setTimeout(() => setLoaderText("Collecting Agents..."), 3000);
    setTimeout(async () => {
      setLoaderText("Producing Tables...");
      const agencyId = sessionStorage.getItem("agencyId");
      const agentId = sessionStorage.getItem("agentId");

      /* async load agency */
      dispatch(getAgency(agencyId, agentId));
      dispatch(setETLDates());
      dispatch(getProductionStatisticsForAgencyAction(parseInt(agencyId)));
      dispatch(getProductionStatisticsForAgencyFullAction(parseInt(agencyId)));
      dispatch(fetchReadyToSellList(agencyId));
      dispatch(fetchAgentReadyToSellInfo(parseInt(agencyId)));
      dispatch(fetchAgentContractList(parseInt(agencyId)));
      dispatch(fetchAgentList());
      dispatch(fetchProductionGridData(parseInt(agencyId)));
      dispatch(setContractStatuses(agencyId));
      dispatch(fetchAgentReadyToSellByCarrier(parseInt(agencyId)));
      dispatch(fetchProductionCarrierAndQtrData(agencyId));
      dispatch(fetchProductionSalesYear());


      let HasProduction = sessionStorage.getItem("HasProduction");
      if (HasProduction !== "false") {
        /* async load production */
        dispatch(setPlanChange(agencyId, agentId)).then(() => {
          dispatch(setCurrentProduction(agencyId, agencyId)).then(() => {
            navigate("/");
          });
        });
      } else {
        navigate("/");
      }
    }, 4700);
  }, [dispatch, navigate]);

  useEffect(() => {
    const isDataLoaded =
      currentProduction?.production &&
      currentProduction?.currentYear &&
      planChange;
    if (!isDataLoaded) {
      LoadInitData();
    } else {
      navigate("/");
    }
  }, [
    currentProduction,
    planChange,
    FetchingProductionError,
    FetchingPlanChangeError,
    navigate,
    LoadInitData,
  ]);

  return (
    <LoadingOverlay
      active={!isDataLoaded}
      className={classes.overlay}
      styles={{
        spinner: (base) => ({
          ...base,
          width: "100px",
        }),
      }}
      spinner //={<RingLoader color={"white"} />}
      text={<Typography style={{ fontSize: 20 }}>{`${loaderText}`}</Typography>}
    >
      <Grid
        className={classes.root}
        container
        spacing={0}
        direction="column"
        alignItems="center"
      />
    </LoadingOverlay>
  );
};

export default LandingPage;
