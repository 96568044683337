import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import HtmlToolTip from "./HtmlTooltip";
import {
  Group,
  Business,
  DomainAdd,
  Dashboard,
  HowToVoteOutlined,
  GroupAdd,
  ExitToApp,
} from "@mui/icons-material";

const LinkButtonWithTooltip = (props) => {
  const { pathName, title, type, onClick } = props;
  let icon = null;
  let dataVal = "";
  switch (type) {
    case "Business":
      icon = <Business fontSize="large" style={{ color: "#FFFFFF" }} />;
      dataVal = "ad__appbar_production";
      break;
    case "DomainAdd":
      icon = <DomainAdd fontSize="large" style={{ color: "#FFFFFF" }} />;
      dataVal = "ad__appbar_production_summary";
      break;
    case "Group":
      icon = <Group fontSize="large" style={{ color: "#FFFFFF" }} />;
      dataVal = "ad__appbar_agents";
      break;
    case "GroupAdd":
      icon = <GroupAdd fontSize="large" style={{ color: "#FFFFFF" }} />;
      dataVal = "ad_appbar_addagent";
      break;
    case "Dashboard":
      icon = <Dashboard fontSize="large" style={{ color: "#FFFFFF" }} />;
      dataVal = "ad__appbar_dashboard";
      break;
    case "Logout":
      icon = <ExitToApp fontSize="large" style={{ color: "#FFFFFF" }} />;
      dataVal = "ad__appbar_logout";
      break;
    case "ContractRequest":
      icon = (
        <HowToVoteOutlined fontSize="large" style={{ color: "#FFFFFF" }} />
      );
      dataVal = "ad__appbar_contractrequest";
      break;
    default:
      return null;
  }

  return (
    <Link to={{ pathname: pathName }}>
      <HtmlToolTip title={title}>
        <Button
          data-tour={dataVal}
          onClick={onClick}
          size="small"
          sx={{ margin: "4px 12px 4px 12px" }}
        >
          {icon}
        </Button>
      </HtmlToolTip>
    </Link>
  );
};

export default LinkButtonWithTooltip;
