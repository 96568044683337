import {
    SET_PRODUCTION_STATISTICS_FOR_AGENCY,
    SET_PRODUCTION_STATISTICS_FOR_AGENCY_ERROR,
    CLEAR_PRODUCITON_STATISTICS_FOR_AGENCY,
    PRODUCTION_STATISTICS_FOR_AGENCY_LOADING,
    SET_PRODUCTION_STATISTICS_FOR_AGENCY_FILTER_MODEL,
    CLEAR_PRODUCTION_STATISTICS_FOR_AGENCY_FILTER_MODEL,
}from "../../constants/action-types";

const initState = {
  dataSummary: [],
  isLoading: false,
  filterModel: {
    items: [],
  },
};

export const productionStatisticsForAgency = (
  state = initState,
  { type, payload }
) => {
  switch (type) {
    case SET_PRODUCTION_STATISTICS_FOR_AGENCY:
      return { ...state, dataSummary: payload };
    case SET_PRODUCTION_STATISTICS_FOR_AGENCY_ERROR:
      return { ...state, loadingError: payload };
    case CLEAR_PRODUCITON_STATISTICS_FOR_AGENCY:
      return { ...state, dataSummary: [] };
    case PRODUCTION_STATISTICS_FOR_AGENCY_LOADING:
      return { ...state, isLoading: payload };
    case SET_PRODUCTION_STATISTICS_FOR_AGENCY_FILTER_MODEL:
      return { ...state, filterModel: payload };
    case CLEAR_PRODUCTION_STATISTICS_FOR_AGENCY_FILTER_MODEL:
      return {
        ...state,
        filterModel: {
          items: [],
        },
      };
    default:
      return state;
  }
};
