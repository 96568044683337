const commonProps = {
  headerAlign: "center",
  align: "center",
  headerClassName: "super-app-theme--header",
  type: "string",
  cellClassName: "super-app-theme--cell",
  sortable: false,
  disableColumnMenu: true,
  flex: 1,
};

const columns = [
  {
    field: "carrierName",
    headerName: "Carrier Name",
    width: 250,
  },
  {
    field: "maTotal",
    headerName: "MA Production",
    width: 250,
  },
  {
    field: "medsuppTotal",
    headerName: "Med Supp Production",
    width: 250,
  },
  {
    field: "pdpTotal",
    headerName: "PDP Production",
    width: 250,
  },
  {
    field: "salesYear",
    headerName: "Sales Year",
    width: 250,
  },
];

const AgentProductionColumns = () => {
  return columns.map((column) => {
    return { ...column, ...commonProps };
  });
};

export default AgentProductionColumns;
